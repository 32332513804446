import { ChevronRightIcon, MinusIcon } from "@heroicons/react/24/outline";
import { useGlobalData } from "./GlobalContext";
import React from "react";
import { isPC } from "@/utils";
import { Button } from "@material-tailwind/react";
import { copyToClipboard } from "@/utils/clipboard";
import { WeChat, Drag, FullScreen } from "../svg";

export const AppChat = () => {
  const { appInfo, showChat, setShowChat, utils } = useGlobalData();
  const [loaded, setloaded] = React.useState(false);
  const [loadSuccess, setLoadSuccess] = React.useState(false);
  const element = React.useRef<HTMLDivElement>(null);
  const [draging, setDraging] = React.useState(false);
  React.useEffect(() => {
    if (showChat) {
      setloaded(true);
      if (element.current) {
        element.current.style.display = "block";
      }
    }
  }, [showChat]);

  return (
    <div className="w-full h-full relative">
      {loaded && (
        <iframe
          onLoad={() => {
            setLoadSuccess(true)
          }}
          className={`w-full h-full ${draging ? "hidden" : "block"}`}
          src={appInfo?.aiAddress + ""}
        ></iframe>
      )}
    </div>
  );

  return loaded ? (
    <div
      ref={element}
      onAnimationEnd={() => {
        if (!showChat) {
          element.current!.style.display = "none";
        }
      }}
      className={`${
        showChat ? "chat-in" : "chat-out"
      } backdrop-blur-xl bg-white/30  w-[400px]  h-[750px] fixed bottom-5 right-5 z-[100] shadow-black/20 shadow-xl rounded-md overflow-hidden`}
    >
      <div className=" h-8 inline-flex items-center absolute top-3 right-3">
        <div
          onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault(); // Prevent text selection
            setDraging(true);
            const startX = e.clientX;
            const startY = e.clientY;
            const startLeft = element.current?.offsetLeft ?? 0;
            const startTop = element.current?.offsetTop ?? 0;

            let lastUpdateTime = 0;
            const minUpdateInterval = 16; // ~60fps

            const onMouseMove = (e: MouseEvent) => {
              const currentTime = Date.now();
              if (currentTime - lastUpdateTime < minUpdateInterval) return;

              const newLeft = startLeft + e.clientX - startX;
              const newTop = startTop + e.clientY - startY;

              if (element.current) {
                element.current.style.left = `${newLeft}px`;
                element.current.style.top = `${newTop}px`;
              }

              lastUpdateTime = currentTime;
            };

            const onMouseUp = () => {
              setDraging(false);
              document.removeEventListener("mousemove", onMouseMove);
              document.removeEventListener("mouseup", onMouseUp);
            };

            document.addEventListener("mousemove", onMouseMove);
            document.addEventListener("mouseup", onMouseUp);
          }}
          className="w-8 h-8 cursor-grab flex items-center justify-center"
        >
          <Drag className="w-[26px] h-[26px]" />
        </div>
        <div
          onClick={() => {
            setShowChat(false);
            setTimeout(() => {
              window.open(appInfo?.aiAddress);
            }, 300);
          }}
          className="w-8 h-8 cursor-pointer flex items-center justify-center"
        >
          <FullScreen className="w-8 h-8" />
        </div>
        <div
          onClick={() => setShowChat(false)}
          className="w-8 h-8 cursor-pointer flex items-center justify-center"
        >
          <div className="w-5 h-1 bg-gray-800 rounded-sm"></div>
        </div>
      </div>

      <iframe
        className={`w-full h-full ${draging ? "hidden" : "block"}`}
        src={appInfo?.aiAddress + ""}
      ></iframe>
    </div>
  ) : (
    <div></div>
  );
};

export const WechatMain = () => {
  const Global = useGlobalData();
  return (
    <>
      <img
        className="w-52 h-52 mx-auto mt-3"
        src="https://cool-jiaojq.oss-cn-beijing.aliyuncs.com/app%2Fbase%2F999c4326b7af4b09a8de04c91aea6090_WechatIMG9293.jpg"
        alt=""
      />
      <div className="flex justify-center items-center mt-3">
        <span>Luman47</span>
        <Button
          onClick={() => {
            copyToClipboard({
              text: "Luman47",
              success() {
                Global.alert({
                  color: "green",
                  message: "复制成功",
                });
                Global.setConfirmModal(false);
              },
            });
          }}
          className="ml-5 flex items-center gap-2"
          size="sm"
        >
          <WeChat />
          点击复制
        </Button>
      </div>
    </>
  );
};

export const BottomButton = () => {
  const Global = useGlobalData();

  const handleGetWechat = () => {
    Global.confrim({
      footer: false,
      showCancel: true,
      message: (
        <div>
          {Global.appInfo?.wxContent.split("\n").map((item, index) => (
            <div key={index} className="text-blue-gray-700 font-medium mb-2">
              {item}
            </div>
          ))}

          <WechatMain />
        </div>
      ),
      title: <div className="text-center text-blue-gray-900">提示</div>,
    });
  };
  return (
    <div className="w-16 h-auto bottom-3 -left-[5rem] md:bottom-10 md:-left-[7rem] absolute z-50">
      <div
        onClick={() => {
          if (!Global.userInfo) {
            return Global.setLoginModal(true);
          }

          if (isPC()) {
            Global.setShowChat(!Global.showChat);
          } else {
            window.open(Global.appInfo?.aiAddress);
          }
        }}
        id="ai"
        style={{
          borderRadius: Global.showChat ? "10px 0px 0px 10px" : "100px",
        }}
        className={`${
          Global.showChat ? "-right-20 bg-gray-50 w-8" : "bg-white w-16"
        } h-16 cursor-pointer rounded-[100px] rounded-r-0 relative   flex justify-center items-center shadow-md`}
      >
        {Global.showChat ? (
          <ChevronRightIcon className="w-6 h-7" />
        ) : (
          <img
            className="w-10 h-10 animate-duration-300 animate-pulse"
            src={Global.utils.oss(
              "/app%2Fbase%2F472d0068f99c473496cfc4fb0779bd9b_app_base_20240902_gpt.png"
            )}
            alt=""
          />
        )}
      </div>

      {!!Global.appInfo?.showWeChat && (
        <div
          onClick={() => handleGetWechat()}
          className="w-16 h-16 cursor-pointer rounded-[100px] mt-5 bg-blue-gray-900 flex justify-center items-center shadow-md"
        >
          <WeChat className="w-8 h-8" />
        </div>
      )}
    </div>
  );
};
