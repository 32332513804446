import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Switch,
} from "@material-tailwind/react";
import { useHttp } from "@/hooks/useFetch";
import { useGlobalData } from "../Layout/GlobalContext";
import { ConfrimModal } from "../Layout/Confrim";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { validatePhoneNumber } from "@/utils/index";
import { Captcha } from "./Captcha";

export function RegisterDialog() {
  const { Http } = useHttp();
  const {
    registerModal,
    setRegisterModal,
    setLoginModal,
    alert,
    login,
    registerCallback,
  } = useGlobalData();

  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [captchaId, setCaptchaId] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [submit, setSubmit] = useState(false);
  const [authCode, setAuthCode] = useState(localStorage.code);

  const [confrimModal, setConfrimModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState("");

  const [time, setTime] = useState(0);
  const timeRef = React.useRef(0);
  const captchaRef = React.useRef<{ refresh: () => void }>();

  const handleCloseClick = () => {
    setRegisterModal(false);
  };

  const handleRegister = async () => {
    setSubmit(true);
    if (!validatePhoneNumber(phone) || !password || !password2) return;
    if (password !== password2) return;
    try {
      await Http.post("/app/user/login/register-phone", {
        phone,
        password,
        code,
        captchaId,
        authCode,
      });
    } catch (error) {
      captchaRef.current?.refresh();
      return;
    }

    alert({
      color: "green",
      message: "注册成功",
    });

    setRegisterModal(false);
    // setLoginModal(true);
    setSubmit(false);

    await login(phone, password);
  };

  const handleSendCode = async () => {
    if (!phone) return;
    const data: string = await Http.post("/app/user/login/phone-code", {
      phone,
    });
    setPhoneCode(data);
    setConfrimModal(true);
    setTime(60);
    timeRef.current = 60;
    const timer = setInterval(() => {
      setTime((time) => time - 1);
      timeRef.current -= 1;
      if (timeRef.current === 0) {
        clearInterval(timer);
      }
    }, 1000);
  };

  useEffect(() => {
    if (registerModal) {
      setAuthCode(localStorage.code);
    }
  }, [registerModal])
  return (
    <>
      <Dialog
        size="md"
        open={registerModal}
        handler={() => setRegisterModal(true)}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-full md:max-w-[24rem]">
          <CardBody className="flex flex-col gap-4">
            <XCircleIcon
              className="absolute w-7 right-5 top-5 cursor-pointer"
              onClick={handleCloseClick}
            />
            <Typography variant="h4" color="blue-gray">
              注册
            </Typography>

            
            <Typography className="-mb-2" variant="h6">
              下单手机号
            </Typography>
            <Input
              error={submit && !validatePhoneNumber(phone)}
              type="text"
              maxLength={11}
              onChange={({ target }) => setPhone(target.value)}
              crossOrigin=""
              label="手机号"
              size="lg"
            />

            {/* <div className="relative flex w-full max-w-[24rem]">
              <Input
                crossOrigin=""
                error={submit && !code}
                type="text"
                size="lg"
                label="Code"
                value={code}
                onChange={({ target }) => setCode(target.value)}
                className="pr-20"
                containerProps={{
                  className: "min-w-0",
                }}
              />
              <Button
                onClick={() => handleSendCode()}
                size="sm"
                disabled={!!time}
                className="!absolute right-1.5 top-1.5 rounded w-"
              >
                {time ? `${time}s` : "获取验证码"}
              </Button>
            </div> */}
            <Typography className="-mb-2" variant="h6">
              设置密码
            </Typography>
            <Input
              error={submit && !password}
              type="password"
              onChange={({ target }) => setPassword(target.value)}
              crossOrigin=""
              label="密码"
              size="lg"
            />

            <Input
              error={submit && (!password2 || password !== password2)}
              type="password"
              onChange={({ target }) => setPassword2(target.value)}
              crossOrigin=""
              label="确认密码"
              size="lg"
            />

            <div className="md:flex">
              <Input
                error={submit && !code}
                type="text"
                onChange={({ target }) => setCode(target.value)}
                crossOrigin=""
                label="验证码"
                className="w-full"
              />
              <div className="w-[150px] h-[44px]">
                <Captcha ref={captchaRef} onChange={(id) => setCaptchaId(id)} />
              </div>
            </div>
            {authCode && <Input
              error={submit && !authCode}
              type="text"
              value={authCode}
              onChange={({ target }) => setAuthCode(target.value)}
              crossOrigin=""
              label="授权码"
              size="lg"
            />}
          </CardBody>
          <CardFooter className="pt-0">
            <Button
              variant="gradient"
              onClick={() => handleRegister()}
              fullWidth
            >
              注册
            </Button>
            <Typography variant="small" className="mt-4 flex justify-center">
              已经有账号了？
              <Typography
                as="a"
                href="#signup"
                variant="small"
                color="blue-gray"
                className="ml-1 font-bold"
                onClick={() => {
                  handleCloseClick();
                  setLoginModal(true);
                }}
              >
                登录
              </Typography>
            </Typography>
          </CardFooter>
        </Card>
      </Dialog>
      <ConfrimModal
        header={
          <div className="text-xxl font-bold text-center w-full mt-5">
            这是你的验证码
          </div>
        }
        content={
          <div className="text-3xl font-bold text-center w-full">
            {phoneCode}
          </div>
        }
        open={confrimModal}
        onConfirm={() => setConfrimModal(false)}
        onCancel={() => setConfrimModal(false)}
      />
    </>
  );
}
