import store from "./store";

const { DEV, VITE_APP_BASE_URL, VITE_OPEN_PROXY } = import.meta.env;

const fetchStream = (url: string, params: {
    method?: string;
    headers?: HeadersInit;
    body?: BodyInit;
    onmessage?: (data: any) => void;
    onclose?: () => void;
}) => {
    const { onmessage, onclose, ...otherParams } = params;
    const controller = new AbortController();
    const signal = controller.signal;

    const push = async (controller: ReadableStreamDefaultController<any>, reader: ReadableStreamDefaultReader<Uint8Array> | undefined) => {
        try {
            const { value, done } = await reader!.read();
            const decoder = new TextDecoder('utf-8');
            if (done) {
                controller.close();
                // onclose?.();
            } else {
                onmessage?.(decoder.decode(value));
                controller.enqueue(value);
                push(controller, reader);
            }
        } catch (error) {
            controller.error(error);
            onclose?.();
        }
    };

    const fetchPromise = fetch(VITE_APP_BASE_URL + url, {
        ...otherParams,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: store.getItem("token")!
        },
        signal: signal
    })
        .then((response) => {
            const reader = response.body?.getReader();
            const stream = new ReadableStream({
                start(controller) {
                    push(controller, reader);
                },
            });
            return stream;
        })
        .then((stream) => new Response(stream, { headers: { 'Content-Type': 'text/html' } }).text());

    return {
        // fetchPromise,
        abort: () => controller.abort()
    };
};

export default fetchStream;